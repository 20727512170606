:root {
  // Fonts
  --font-family: 'San Francisco Text', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  --font-family-display: 'San Francisco Display', 'Work Sans', 'Trebuchet MS', sans-serif;

  // Colors
  --color-brand: #1f2227;
  --color-brand-accent: #f5b54c;
  --color-light: white;
  --color-gray: lightgray;
  --color-dark-gray: #6F6F6F;
  --color-dark: #101010;
  --color-anchor-color: var(--color-dark);

  // Sizes
  --header-size: 50px;
}
