@import '../../styles/partials/includes';

footer {
  position: relative;
  z-index: 1; // Hide the images from overtaking the footer

  nav {
    @include restrain-content();

    ol {
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      padding-bottom: 15px;

      li {
        flex: 0 0 100%;
        margin-bottom: 15px;
        text-align: center;
      }

      li,
      a {
        @extend %display-font;
        font-weight: bold;
        font-size: 16px;
      }

      li {
        color: var(--color-dark-gray);
      }

      a {
        color: var(--color-anchor-color);
        text-decoration: none;
      }
    }

    ol.footer-links {
      justify-content: space-evenly;

      li {
        padding: 0 15px;
        flex: auto;
      }
    }
  }
}

@media screen and (min-width: 801px) {
  .content {
    &-body {
      display: flex;

      &--description {
        flex: 0 0 50%;
        display: block;

        &-text {
          margin-right: 30px;

          &--buttons {
            &-btn {
              margin: 0;
              flex: none;

              &:first-of-type {
                margin-right: 20px;
              }

              img,
              svg {
                margin: 0;
              }
            }
          }
        }
      }

      &--example {
        flex: 0 0 50%;
        min-height: 450px;
        background-position: bottom right;
        justify-content: flex-end;

        img {
          max-height: 450px;
        }
      }
    }
  }
}
