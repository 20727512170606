@mixin clearfix() {
  &::before,
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin restrain-content() {
  max-width: 1028px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}
