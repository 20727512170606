@import './partials/includes';

:root {
  --logo-size: 24px;
}

html,
body {
  color: var(--color-light);
  text-rendering: optimizeLegibility;
  font-family: var(--font-family);
}

h1 {
  font-size: var(--header-size);
  margin-bottom: 2rem;

  @extend %display-font;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 30px;

  @extend %display-font;
  color: var(--color-brand-accent);
}

h3, h4 {
  display: block;
  font-weight: bold;
  padding-bottom: 15px;
  margin-bottom: 0 !important;
  color: var(--color-brand-accent);
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 30px;
  text-transform: uppercase;

  @extend %display-font;
}

h4 {
  margin-top: 0;
  color: var(--color-light);
  font-size: 1rem;
}

a {
  &,
  a:link,
  a:visited {
    color: var(--color-brand-accent);
  }
}

p {
  font-size: 16px;
  line-height: 1.3em;
  margin-bottom: 15px;

  sup {
    font-size: 10px;
  }
}

small {
  font-size: 14px;
}

// Finishing touches at the bottom of the cascade
@import './partials/garnishes';
