// Lets get fancy with some things, why not.
// Give anchor links in primary navigation areas some cool underline treatment
a.link:link:not(.normal-link) {
  color: var(--color-anchor-color);
  text-decoration: none;
  position: relative;

  &.a11y,
  &:hover {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -2px;
      left: 0;
      background-color: var(--color-anchor-color);
    }
  }

  &.blue {
    &::after {
      background-color: var(--color-brand-accent);
    }
  }
}

// Start "Sticky Footer"
/* Sticky footers are annoying...
   Instead lets make the rest of the body content
   the same color as the footer and the page content
   background the color that we want it to be.

   Some people also find it jarring when they expect
   the html to load a white screen and dont get that,
   so we deliver that as well. */
html {
  background-color: white;
}
body,
footer {
  background-color: var(--color-light);
}
.content {
  background-color: var(--color-dark);
}
// End "Sticky Footer"
