@import '../../styles/partials/includes';

header {
  background-color: var(--color-light);

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;

    @include restrain-content();

    p {
      @extend %display-font;

      color: var(--color-brand);
      font-size: 30px;
      margin-bottom: 0;
    }

    a:link {
      text-decoration: none;
    }
  }
}
