@import '../../styles/partials/includes';

.page {
  display: flex;
  align-items: center;

  &-body {
    width: 100%;
    background-color: var(--color-brand);

    &--legal {
      padding-top: 4rem;
      padding-bottom: 4rem;

      a {
        color: var(--color-light);
        text-decoration: underline;
      }

      h6 {
        color: var(--color-light);
      }

      ol {
        margin-top: 1rem;

        li {
          list-style: disc;
        }
      }
    }

    &--content {
      @include restrain-content();

      h1 {
        line-height: 50px;
      }

      h5 {
        text-transform: uppercase;
        padding-bottom: 15px;
      }

      h6 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
        margin-top: 30px;
        text-transform: uppercase;

        @extend %display-font;
      }

      p, li {
        span {
          font-weight: bold;
        }
      }

      p + ol,
      p + ul {
        margin-top: -15px;
      }

      ul,
      ol {
        padding-left: 30px;
        padding-bottom: 15px;

        ol, ul {
          padding-top: 15px;
        }

        li {
          margin-bottom: 15px;
        }
      }

      ul {
        li {
          list-style: disc;
        }
      }

      p {
        padding-bottom: 15px;
      }
    }
  }
}
