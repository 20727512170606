@import '../../styles/partials/includes';

.home {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  padding-top: 4rem;
  padding-bottom: 4rem;

  &-icon {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    img {
      border-radius: 25px;
      height: 5rem;
      margin-right: 1rem;
      float: left;

      &::before,
      &::after {
        clear: both;
        display: table;
      }
    }

    h1 {
      margin-bottom: 0;
    }
  }

  &-description {
    p {
      padding-bottom: 15px;
    }
  }

  &-buttons {
    a {
      display: inline-block;

      &:first-of-type {
        margin-right: 10px;
      }
    }

    img {
      max-height: 40px;
    }
  }

  &-image {
    flex: 0 0 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 400px;
    }
  }
}

@media screen and (min-width: 801px) {
  .home {
    grid-template-columns: 1fr 1fr;

    &-description {

      &-text {
        margin-right: 30px;

        &--buttons {
          &-btn {
            margin: 0;
            flex: none;

            &:first-of-type {
              margin-right: 20px;
            }

            img,
            svg {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
